<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="6" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :href="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10" class="pt-1">
                    <span class="headline font-weight-medium">Sesiones de vendedores</span>
                </v-col>
                <v-col cols="2" class="pt-1">
                    <v-btn dark color="#023145" width="140" class="float-right" @click="handleShowFilters">
                        Filtros
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="pt-1">
                    <v-btn
                        dark 
                        color="success" 
                        width="140" 
                        class="float-right"
                        :loading="is_download"
                        @click="handleDownloadReport">
                        Descargar XLS
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="pt-1">
                    <v-card>
                        <v-data-table
                            :headers="headers"
                            :items="user_sessions"
                            item-key="id"
                            :items-per-page="10"
                            class="elevation-1 w-table"
                            height="57vh"
                            fixed-header
                        >
                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </template>

        <sessions-filters ref="sessionsFilters"></sessions-filters>
    </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex';

import OfficeLogin from '../../../components/auth/OfficeLogin';
import SessionsFilters from '../../../components/reports/SessionsFilters';

export default {
    name: 'userSessions',
    components: {
        OfficeLogin,
        SessionsFilters
    },
    data () {
        return {
            itemsBreadcrumbs: [
                {
                    text: 'Reportes',
                    disabled: true,
                    href: '',
                }
            ],
            headers: [
                { text: 'VENDEDORES', value: 'fullname', sortable: false },
                { text: 'FECHA INGRESO', value: 'login_date', sortable: false },
                { text: 'HORA INGRESO', value: 'login_hour', sortable: false },
                { text: 'FECHA CIERRE', value: 'logout_date', sortable: false },
                { text: 'HORA CIERRE', value: 'logout_hour', sortable: false },       
                { text: '', value: 'detail', sortable: false, align: 'end' }
            ],
            is_download: false,
            date_from: this.$moment().subtract(7, 'days').format('YYYY-MM-DD'),
            date_until: this.$moment().format('YYYY-MM-DD')
        }
    },
    computed: {
        ...mapState('user-sessions', [
            'user_sessions',
            'filters',
            'file_name'
        ])
    },
    methods: {
        ...mapActions('user-sessions', [
            'getAllUserSessions',
            'setFilters',
            'downloadExcel'
        ]),
        ...mapActions('users', [
            'getSellers'
        ]),
        handleShowFilters () {
            this.$refs.sessionsFilters.show();
        },
        async handleDownloadReport () {
            this.is_download = true;
            let filters_data = Object.assign({}, this.filters);
            await this.downloadExcel(filters_data);
            this.is_download = false;
            if (this.file_name) {
                let url = process.env.VUE_APP_API_URL_BASE + '/' + this.file_name;
                window.open(url);
            }
        }
    },
    created () {
        this.getSellers();
        this.setFilters({
            date_from: this.date_from,
            date_until: this.date_until
        });
        this.getAllUserSessions(this.filters);
    }
}
</script>

<style scoped>

</style>